import React from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import {
  Pressable,
  Button,
  Tag,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  Progress,
  DownloadOutlineIcon,
  StarOutlineIcon,
  AgeGatedPressable,
  CallToActionLabel,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@gonoodle/gn-universe-ui";
import { differenceInDays, differenceInHours } from "date-fns";
import { useSearchParams, usePathname } from "next/navigation";
import { concatenateQueryParams } from "@gonoodle/gn-universe-utils";

import { useLogEvent } from "../../contexts/Analytics";
import {
  useBadgeClaimCount,
  useCurriculum,
  useHasCurriculumAccess,
  useMonthlyBadgesQuery,
  useClassicBadgesQuery,
  useBadgeRulesProgress,
  useListPagination,
  useRouter,
} from "../../hooks";
import {
  BADGE_EARNING_MODELS,
  BADGES_TYPES,
  ROUTE_PREFIX,
  URLS,
  TROPHY_TYPES,
} from "../../constants";
import Link from "../Link";
import TrophyAvatar from "../TrophyAvatar";
import LottiePlayer from "../LottiePlayer";
import generalBadgeEarnedAnimationTemplate from "../../public/lottie/achievements/general-badge-earned-template.json";

const VARIANTS = {
  CELEBRATION: "celebration",
  STATIC: "static",
};

const CallToActionLink = ({
  label,
  link,
  linkProps = {},
  variant = "light-outline",
}) =>
  label && link ? (
    <Link href={link} {...linkProps}>
      <CallToActionLabel
        variant={variant}
        size="sm"
        className="md:text-sm md:h-button-md lg:text-md lg:font-bold lg:h-button-lg"
      >
        {label}
      </CallToActionLabel>
    </Link>
  ) : null;

const Avatar = ({ title, isClaimed, earnedImage, defaultImage, className }) => (
  <TrophyAvatar
    className={className}
    src={isClaimed ? earnedImage.regular3x : defaultImage.regular3x}
    imageProps={{
      width: 500,
      height: 500,
      fill: false,
    }}
    fallback={title}
  />
);

const Actions = ({
  isPremium,
  label,
  link,
  printableLink,
  isClaimed,
  title,
  badgeType,
}) => {
  const router = useRouter();
  const hasCurriculumAccess = useHasCurriculumAccess();
  const curriculum = useCurriculum();

  const { logEvent: logBadgeDownloadedEvent } = useLogEvent({
    event: "Badge Downloaded",
    properties: {
      title,
      badgeType,
    },
    options: {
      includeReferrer: false,
    },
  });

  return (
    <>
      {isPremium ? (
        hasCurriculumAccess ? (
          <Button
            variant={BUTTON_VARIANTS.vivid}
            size={BUTTON_SIZES.sm}
            className="md:text-sm md:h-button-md lg:text-md lg:font-bold lg:h-button-lg"
            onPress={() =>
              router.push(`/${ROUTE_PREFIX.CURRICULUM}/${curriculum.id}`)
            }
          >
            <div className="flex flex-row justify-center items-center space-x-2">
              <StarOutlineIcon className="w-[18px] h-[18px] md:w-6 md:h-6 text-white shrink-0" />
              <span className="capitalize">Go to SuperNoodle</span>
            </div>
          </Button>
        ) : (
          // Follows the same styles as the vivid Button component variant with size sm.
          <AgeGatedPressable
            onPress={() => router.push(URLS.SUPERNOODLE_URL)}
            className="text-white inline-flex items-center justify-center select-none rounded transition-colors focus:outline-none text-xs h-button-sm px-3 bg-purple border border-transparent hover:bg-purple-400 focus:bg-purple focus:border-purple-300 focus:ring-purple-300 focus:ring shadow-[0_4px_0_0_rgba(73,22,169,1),0_8px_0_0_rgba(0,0,0,0.25)] md:text-sm md:h-button-md lg:text-md lg:font-bold lg:h-button-lg"
          >
            <div className="flex flex-row justify-center items-center space-x-2">
              <StarOutlineIcon className="w-[18px] h-[18px] md:w-6 md:h-6 text-white shrink-0" />
              <span className="capitalize">Get SuperNoodle</span>
            </div>
          </AgeGatedPressable>
        )
      ) : null}

      {label && link && <CallToActionLink label={label} link={link} />}

      {printableLink && isClaimed && (
        <CallToActionLink
          link={printableLink}
          linkProps={{
            target: "_blank",
            rel: "noopener noreferrer",
            onPress: () => {
              logBadgeDownloadedEvent();
            },
          }}
          label={
            <div className="flex flex-row justify-center items-center space-x-2">
              <DownloadOutlineIcon className="w-[18px] h-[18px] md:w-6 md:h-6 shrink-0" />

              <span className="capitalize">Download Badge Certificate</span>
            </div>
          }
        />
      )}
    </>
  );
};

const ExpirationInfo = ({ expirationDate }) => {
  const days = differenceInDays(expirationDate, new Date());
  const hours = differenceInHours(expirationDate, new Date());

  if (hours < 0) {
    return null;
  }

  return (
    <div className="flex flex-row space-x-3">
      <span className="text-md text-white font-extrabold opacity-90">
        Expires in:
      </span>

      <span className="text-md text-white font-semibold opacity-90">
        {days >= 1
          ? `${days} day${days > 1 ? "s" : ""}`
          : hours >= 1
          ? `${hours} hour${hours > 1 ? "s" : ""}`
          : "Less than an hour"}
      </span>
    </div>
  );
};

const Highlights = ({ id, type, earningModel }) => {
  const { badgeClaimCount } = useBadgeClaimCount({ id });
  const shouldRenderTags =
    badgeClaimCount >= 1 || type === BADGES_TYPES.LIMITED;

  return (
    shouldRenderTags && (
      <div className="flex flex-row space-x-2">
        {type === BADGES_TYPES.LIMITED && (
          <Tag variant={Tag.VARIANTS.limited} className="w-fit" />
        )}
        {earningModel === BADGE_EARNING_MODELS.RECURRING &&
          badgeClaimCount >= 1 && (
            <Tag
              label={`${badgeClaimCount} time${
                badgeClaimCount > 1 ? "s" : ""
              } earned`}
              variant={Tag.VARIANTS.earned}
              className="w-fit"
            />
          )}
        {earningModel === BADGE_EARNING_MODELS.ONE_TIME &&
          badgeClaimCount === 1 && (
            <Tag variant={Tag.VARIANTS.earned} className="w-fit" />
          )}
      </div>
    )
  );
};

const Title = ({ title }) => (
  <div className="text-lg text-white font-extrabold font-display md:font-italic md:text-2xl lg:text-3xl">
    {title}
  </div>
);

const Description = ({ description }) =>
  description && (
    <div className="text-xs text-white opacity-90 md:text-sm lg:text-md">
      {description}
    </div>
  );

const NavigationControls = ({
  currentIndex,
  totalCount,
  isPrevDisabled,
  isNextDisabled,
  onPrev,
  onNext,
  label,
}) => (
  <>
    <Pressable disabled={isPrevDisabled} onPress={onPrev}>
      <ChevronLeftIcon
        className={twMerge(
          "w-6 h-6 text-white",
          isPrevDisabled && "text-gray-500",
        )}
      />
    </Pressable>

    <span className="text-white text-sm font-extrabold">
      {label} {`${currentIndex} of ${totalCount}`}
    </span>

    <Pressable disabled={isNextDisabled} onPress={onNext}>
      <ChevronRightIcon
        className={twMerge(
          "w-6 h-6 text-white",
          isNextDisabled && "text-gray-500",
        )}
      />
    </Pressable>
  </>
);

const GeneralBadgeAnimatedDetails = ({ badge }) => {
  const lottiePlayerRef = React.useRef(null);
  const [
    badgeEarnAnimationCompleted,
    setBadgeEarnAnimationCompleted,
  ] = React.useState(false);
  const [animationData, setAnimationData] = React.useState();

  const {
    id,
    premium,
    title,
    badgeType,
    earnedImage,
    description,
    publishEndsAt,
    ctaText,
    ctaLink,
    rules,
    earningModel,
    printableBadgePdf,
    printableBadgeImage,
  } = badge;
  const expirationDate = new Date(publishEndsAt);
  const printableLink = printableBadgePdf || printableBadgeImage?.regular3x;
  const shouldRenderBadgeActions = (ctaText && ctaLink) || printableLink;

  React.useEffect(() => {
    const updatedAnimation = { ...generalBadgeEarnedAnimationTemplate };

    updatedAnimation.assets = generalBadgeEarnedAnimationTemplate.assets.map(
      (asset) => {
        if (asset.id === "image_wrapper") {
          return { ...asset, p: earnedImage.regular3x };
        }
        return asset;
      },
    );

    setAnimationData(updatedAnimation);
  }, [earnedImage.regular3x]);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col justify-center flex-1 md:flex-row md:items-center overflow-x-clip">
        <motion.div
          className="self-center aspect-1 w-[350px] md:w-[400px] lg:w-[650px]"
          layout={true}
        >
          <LottiePlayer
            ref={lottiePlayerRef}
            animationData={animationData}
            loop={false}
            play={true}
            onComplete={() => setBadgeEarnAnimationCompleted(true)}
            className="w-full h-full"
          />

          <audio src="/audio/teleport.mp3" autoPlay={true} />
        </motion.div>

        <motion.div
          animate={
            badgeEarnAnimationCompleted
              ? { opacity: 1, y: "0%", x: "0%" }
              : {
                  opacity: 0,
                  y: "var(--motion-axis-y)",
                  x: "var(--motion-axis-x)",
                }
          }
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 70,
            restDelta: 0.01,
          }}
          className={twMerge(
            "flex-col w-full space-y-4 lg:space-y-6 md:max-w-1/2 lg:max-w-1/3 hidden [--motion-axis-y:100%] [--motion-axis-x:0%] md:[--motion-axis-x:100%] md:[--motion-axis-y:0%]",
            badgeEarnAnimationCompleted && "flex",
          )}
        >
          <Title title={title} />

          {
            // The progress is calculated this way because the badge has already been earned,
            // meaning each rule is considered fully complete. To visually represent this,
            // the `value` (current progress) is set equal to the `maxValue` (required progress).
            // This ensures the progress bar always appears as 100% complete, reflecting the
            // fully achieved state of the badge. The `score` represents the target value for
            // each rule, which has been met or exceeded when the badge is earned.
          }
          {rules.map(({ id: ruleId, score }) => (
            <Progress
              key={ruleId}
              label={rules.find((rule) => rule.id === ruleId).title}
              value={score}
              maxValue={score}
              valueLabel={`${score} / ${score}`}
              className="text-md"
              size={Progress.SIZES.LG}
            />
          ))}

          <ExpirationInfo expirationDate={expirationDate} />

          <Highlights earningModel={earningModel} id={id} type={badgeType} />

          <Description description={description} />

          {shouldRenderBadgeActions && (
            <div className="flex flex-col flex-wrap gap-4 lg:flex-row">
              <Actions
                isPremium={premium}
                label={ctaText}
                link={ctaLink}
                printableLink={printableLink}
                isClaimed={true}
                title={title}
                badgeType={badgeType}
              />
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

const PremiumBadgeAnimatedDetails = ({ badge }) => {
  const lottiePlayerRef = React.useRef(null);
  const [
    badgeEarnAnimationCompleted,
    setBadgeEarnAnimationCompleted,
  ] = React.useState(false);

  const {
    id,
    premium,
    title,
    badgeType,
    earnedImage,
    description,
    publishEndsAt,
    ctaText,
    ctaLink,
    rules,
    earningModel,
    printableBadgePdf,
    printableBadgeImage,
  } = badge;
  const expirationDate = new Date(publishEndsAt);
  const printableLink = printableBadgePdf || printableBadgeImage?.regular3x;

  return (
    <div className="flex flex-col flex-1">
      <div className="relative flex flex-col flex-1 justify-center space-y-4 md:flex-row md:space-y-0 md:items-center md:space-x-12 lg:space-x-32 overflow-x-clip">
        <div className="absolute inset-0">
          <LottiePlayer
            ref={lottiePlayerRef}
            path="/lottie/achievements/premium-badge-earned.json"
            loop={false}
            play={true}
            className="w-full h-full"
          />

          <audio src="/audio/teleport.mp3" autoPlay={true} />
        </div>

        <motion.div
          className="self-center"
          layout={true}
          transition={{ delay: 1 }}
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: [0, 1.2, 1] }}
            transition={{
              delay: 1,
              duration: 2,
            }}
            onAnimationComplete={() => setBadgeEarnAnimationCompleted(true)}
          >
            <Avatar
              title={title}
              isClaimed={true}
              earnedImage={earnedImage}
              className="aspect-1 w-[250px] md:w-[312px] lg:w-[500px]"
            />
          </motion.div>
        </motion.div>

        <motion.div
          animate={
            badgeEarnAnimationCompleted
              ? { opacity: 1, y: "0%", x: "0%" }
              : {
                  opacity: 0,
                  y: "var(--motion-axis-y)",
                  x: "var(--motion-axis-x)",
                }
          }
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 70,
            restDelta: 0.01,
            delay: 1,
          }}
          className={twMerge(
            "flex-col w-full space-y-4 lg:space-y-6 md:max-w-1/2 lg:max-w-1/3 hidden [--motion-axis-y:100%] [--motion-axis-x:0%] md:[--motion-axis-x:100%] md:[--motion-axis-y:0%]",
            badgeEarnAnimationCompleted && "flex",
          )}
        >
          <Title title={title} />

          {
            // The progress is calculated this way because the badge has already been earned,
            // meaning each rule is considered fully complete. To visually represent this,
            // the `value` (current progress) is set equal to the `maxValue` (required progress).
            // This ensures the progress bar always appears as 100% complete, reflecting the
            // fully achieved state of the badge. The `score` represents the target value for
            // each rule, which has been met or exceeded when the badge is earned.
          }
          {rules.map(({ id: ruleId, score }) => (
            <Progress
              key={ruleId}
              value={score}
              label={rules.find((rule) => rule.id === ruleId).title}
              maxValue={score}
              valueLabel={`${score} / ${score}`}
              className="text-md"
              size={Progress.SIZES.LG}
            />
          ))}

          <ExpirationInfo expirationDate={expirationDate} />

          <Highlights earningModel={earningModel} id={id} type={badgeType} />

          <Description description={description} />

          <div className="flex flex-col flex-wrap gap-4 lg:flex-row">
            <Actions
              isPremium={premium}
              label={ctaText}
              link={ctaLink}
              printableLink={printableLink}
              isClaimed={true}
              title={title}
              badgeType={badgeType}
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const BadgeStaticDetails = ({ id: initialBadgeId }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { monthlyBadges } = useMonthlyBadgesQuery();
  const { classicBadges } = useClassicBadgesQuery();
  const badges = [...monthlyBadges, ...classicBadges];
  const {
    paginatedList,
    currentListIndex,
    isFirstList,
    isLastList,
    nextList,
    prevList,
  } = useListPagination({
    list: badges,
    listSize: 1,
    defaultListIndex: badges.findIndex(({ id }) => id === initialBadgeId) + 1,
  });
  const [currentBadge] = paginatedList;
  const {
    id,
    title,
    isEarned,
    isClaimed,
    earnedImage,
    defaultImage,
    publishEndsAt,
    premium,
    ctaText,
    ctaLink,
    rules,
    badgeType,
    earningModel,
    description,
    printableBadgePdf,
    printableBadgeImage,
  } = currentBadge;
  const badgeRulesProgress = useBadgeRulesProgress({ id });
  const searchParams = useSearchParams();

  const expirationDate = new Date(publishEndsAt);
  const printableLink = printableBadgePdf || printableBadgeImage?.regular3x;
  const shouldRenderBadgeActions =
    premium || (ctaText && ctaLink) || printableLink;

  React.useEffect(() => {
    const clonedSearchParams = new URLSearchParams(searchParams.toString());
    const tab = clonedSearchParams.get("tab");

    if (tab === TROPHY_TYPES.BADGES) {
      if (id && id !== clonedSearchParams.get("selected_trophy_id")) {
        clonedSearchParams.set("selected_trophy_id", id);
      } else {
        clonedSearchParams.delete("selected_trophy_id");
      }

      router.replace(
        concatenateQueryParams(
          pathname,
          Object.fromEntries(clonedSearchParams.entries()),
        ),
        {
          scroll: false,
        },
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col flex-1 space-y-4 md:flex-row md:space-y-0 md:items-center md:justify-center md:space-x-12 lg:space-x-32 overflow-x-clip">
        <div className="self-center">
          <Avatar
            title={title}
            isEarned={isEarned}
            isClaimed={isClaimed}
            earnedImage={earnedImage}
            defaultImage={defaultImage}
            className="aspect-1 w-[250px] md:w-[312px] lg:w-[500px]"
          />
        </div>

        <div className="flex flex-col w-full space-y-4 lg:space-y-6 md:max-w-1/2 lg:max-w-1/3">
          <Title title={title} />

          {badgeRulesProgress.map(({ ruleId, currentScore, scoreNeeded }) => (
            <Progress
              key={ruleId}
              label={rules.find((rule) => rule.id === ruleId).title}
              value={currentScore}
              maxValue={scoreNeeded}
              valueLabel={`${currentScore} / ${scoreNeeded}`}
              className="text-md"
              size={Progress.SIZES.LG}
            />
          ))}

          <ExpirationInfo expirationDate={expirationDate} />

          <Highlights earningModel={earningModel} id={id} type={badgeType} />

          <Description description={description} />

          {shouldRenderBadgeActions && (
            <div className="flex flex-col flex-wrap gap-4 lg:flex-row">
              <Actions
                isPremium={premium}
                label={ctaText}
                link={ctaLink}
                printableLink={printableLink}
                isClaimed={isClaimed}
                title={title}
                badgeType={badgeType}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-row justify-center items-center space-x-3 mt-4">
        <NavigationControls
          currentIndex={currentListIndex}
          totalCount={badges.length}
          label="Badge"
          isNextDisabled={isLastList}
          isPrevDisabled={isFirstList}
          onNext={nextList}
          onPrev={prevList}
        />
      </div>
    </div>
  );
};

export default function BadgeDetails({ badge, isEarningBadge }) {
  const variant = isEarningBadge ? VARIANTS.CELEBRATION : VARIANTS.STATIC;

  switch (variant) {
    case VARIANTS.CELEBRATION:
      return badge.premium ? (
        <PremiumBadgeAnimatedDetails badge={badge} />
      ) : (
        <GeneralBadgeAnimatedDetails badge={badge} />
      );

    case VARIANTS.STATIC:
    default:
      return <BadgeStaticDetails id={badge.id} />;
  }
}
